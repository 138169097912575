import React from "react"
import { FormattedMessage, Link, useIntl } from "gatsby-plugin-react-intl"
import { StaticImage } from "gatsby-plugin-image"
import { css } from "@emotion/react"
import styled from "@emotion/styled"
import {size, spacing, color, ensembles, composers } from "../constants"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Person = (props) => (
  <div css={css`
    text-align: center;
    flex: 1 0 150px;
    margin: 20px 20px 0 20px;
    img {
      height: 190px;
      object-fit: cover;
      object-position: 0 10%;
    }
  `}>
    {props.children}
    <p css={css`margin-bottom: 0;`}>{props.name}</p>
  </div>
)


const KontaktPage = () => {
  const intl = useIntl()
  return (
    <Layout title={intl.formatMessage({ id: "kontakt" })}>
      <SEO
        lang={intl.locale}
        title={intl.formatMessage({ id: "kontakt" })}
      />
      <div css={css`
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
      `}>
        <StaticImage
          src="../images/christine-luthi.jpeg"
          quality={95}
          placeholder={"tracedSVG"}
          formats={["AUTO", "WEBP", "AVIF"]}
          alt="Christine Lüthi"
          css={css`margin-bottom: 1em;`}
        />
        <p css={css`margin: 0 1em 1em 1em;`}>
          ORPHEUS - Swiss Chamber Music Competition<br/>
          Geschäftsstelle: LINKultur, Christine Lüthi<br/>
          Schafisweg 33, CH - 2514 Ligerz<br/>
          Tel. <a href="tel:+41 (0)32 315 21 41">+41 (0)32 315 21 41</a>,<br/>
          Mail <a href="mailto:office@orpheus-competition.ch">office@orpheus-competition.ch</a><br/>
        </p>
      </div>
    </Layout>
  )
}

export default KontaktPage